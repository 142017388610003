import React from 'react';
import { Parallax } from "react-parallax";
import CountUp from "react-countup";
import AOS from 'aos';
import { block } from "million/react";
AOS.init();
const image1 = "./img/background/3.jpg";
const Mblockquote = function () {
  return <div className="section bg-top bg-bottom ">
            <Parallax className="py-5" bgImage={image1} strength={300}>
                <div className="py-5 position-relative">
                    <div className="container">
                        <div className="row counter-center">
                            <div className="col-md-3">
                                <div className="de_count text-center">
                                    <h3 className="timer" data-aos="fade" data-aos-delay="200" data-aos-duration="1000" data-aos-easing="ease" data-aos-once="true">
                                        <CountUp start={0} end={13} duration={10} useEasing={true} separator="," />
                                    </h3>
                                    <span className='letter-spacing-p'>Proiecte terminate</span>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="de_count text-center">
                                    <h3 className="timer" data-aos="fade" data-aos-delay="400" data-aos-duration="1000" data-aos-easing="ease" data-aos-once="true"><CountUp start={0} end={13} duration={10} useEasing={true} separator="," /></h3>
                                    <span className='letter-spacing-p'>Clienți mulțumiți</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Parallax>
        </div>;
};
const counterSection = Mblockquote;
export default counterSection;