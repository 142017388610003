import React from 'react';
import AOS from 'aos';
import { block } from "million/react";
AOS.init();
const whatido2 = () => {
  return <P$ />;
};
const P$ = /*million:transform*/block(({}) => {
  return <div className="container">
            <div className="row">
                <div className="col-md-12 text-center" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000" data-aos-easing="ease" data-aos-once="true">
                    <h2 className='letter-spacing-p'>Servicii</h2>
                    <div className="space-border"></div>
                </div>
            </div>
            <div className="spacer-single"></div>
            <div className="row">
                <div className="col-lg-4" data-aos="fade" data-aos-delay="300" data-aos-duration="1000" data-aos-easing="ease" data-aos-once="true">
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="fa fa-paint-brush" aria-hidden="true"></i>
                            <div className="text letter-spacing-p">
                                <h3 className='letter-spacing-p'>Servicii de Dezvoltare Aplicații Web</h3>
                                Experiențe web de neuitat. La noi, designul elegant și funcționalitatea robustă se îmbină pentru a vă diferenția de concurență. Cu cunoștințe avansate în tehnologii de ultimă generație, transformăm viziunea dvs. în realitate. Fiecare proiect este abordat cu atenție și pasiune, adaptându-ne obiectivelor dumneavoastră de afaceri. Lucrăm împreună cu dumneavoastră pentru a oferi soluții personalizate și durabile. Experimentați inovație și un parteneriat pe termen lung cu echipa noastră dedicată.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4" data-aos="fade" data-aos-delay="400" data-aos-duration="1000" data-aos-easing="ease" data-aos-once="true">
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="fa fa-mobile" aria-hidden="true"></i>
                            <div className="text letter-spacing-p">
                                <h3 className='letter-spacing-p'>Dezvoltare Aplicatii mobile Android/IOS</h3>
                                La echipa noastră, suntem specializați în construirea de aplicații mobile de înaltă performanță. Dezvoltatorii noștri talentați îmbină tehnologii de ultimă generație cu un design elegant, livrând aplicații mobile prietenoase și atractive care ridică brandul tău la un nivel superior. Experiența noastră în domeniu ne permite să creăm aplicații mobile personalizate și optimizate, adaptate nevoilor și cerințelor tale specifice. Lucrând împreună cu noi, poți avea încredere că vei obține o aplicație mobilă de înaltă calitate, ce oferă o experiență memorabilă utilizatorilor. Alege-ne ca partener în dezvoltarea aplicației mobile și bucură-te de succesul în mediul mobil.

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4" data-aos="fade" data-aos-delay="500" data-aos-duration="1000" data-aos-easing="ease" data-aos-once="true">
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="fa fa-superpowers" aria-hidden="true"></i>
                            <div className="text letter-spacing-p">
                                <h3 className='letter-spacing-p'>Deployment și Management Cloud: AWS, App Stores</h3>
                                Vă oferă soluții personalizate pentru deployment și management pe AWS, DigitalOcean și App Stores. Cu experiență vastă și competențe tehnice avansate, vă asistăm în crearea, configurarea și gestionarea infrastructurii dvs. în cloud. De asemenea, vă ajutăm să publicați aplicația în App Stores, respectând toate cerințele și standardele necesare. Succesul online depinde de o infrastructură solidă și scalabilă, iar noi suntem aici pentru a vă ghida în acest proces complex.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
}, {
  svg: false,
  shouldUpdate: (a, b) => true
});
whatido2.__block_callable__ = true;
const whatIdoSection = whatido2;
export default whatIdoSection;