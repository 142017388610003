import React from 'react';
import { block } from "million/react";
const footer2 = () => {
  return <P$ />;
};
const P$ = /*million:transform*/block(({}) => {
  return <footer>
            <div className="container">
                <div className="row">
                    <div className="counter-center">
                        <a className="letter-spacing-p" href="https://www.envato.com" target="_blank" rel="noreferrer">
                            <span className="copy letter-spacing-p">&copy; Copyright 2023 - TechWizards</span>
                        </a>
                    </div>
                </div>
            </div>
        </footer>;
}, {
  svg: false,
  shouldUpdate: (a, b) => true
});
footer2.__block_callable__ = true;
const footerSection = footer2;
export default footerSection;