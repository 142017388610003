import React from 'react';
import Masonry from "react-masonry-component";
import { createGlobalStyle } from 'styled-components';
import AOS from 'aos';
import { block } from "million/react";
AOS.init();
const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;
const Gallery = function () {
  const [lighbx, setlighbx] = React.useState(false);
  const handleBtnClick = () => {
    setlighbx(!lighbx);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose = () => {
    setlighbx(!lighbx);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };
  const [lighbx2, setlighbx2] = React.useState(false);
  const handleBtnClick2 = () => {
    setlighbx2(!lighbx2);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose2 = () => {
    setlighbx2(!lighbx2);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };
  const [lighbx3, setlighbx3] = React.useState(false);
  const handleBtnClick3 = () => {
    setlighbx3(!lighbx3);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose3 = () => {
    setlighbx3(!lighbx3);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };
  const [lighbx4, setlighbx4] = React.useState(false);
  const handleBtnClick4 = () => {
    setlighbx4(!lighbx4);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose4 = () => {
    setlighbx4(!lighbx4);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };
  const [lighbx5, setlighbx5] = React.useState(false);
  const handleBtnClick5 = () => {
    setlighbx5(!lighbx5);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose5 = () => {
    setlighbx5(!lighbx5);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };
  const [lighbx6, setlighbx6] = React.useState(false);
  const handleBtnClick6 = () => {
    setlighbx6(!lighbx6);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose6 = () => {
    setlighbx6(!lighbx6);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };
  const [lighbx7, setlighbx7] = React.useState(false);
  const handleBtnClick7 = () => {
    setlighbx7(!lighbx7);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose7 = () => {
    setlighbx7(!lighbx7);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };
  const [lighbx8, setlighbx8] = React.useState(false);
  const handleBtnClick8 = () => {
    setlighbx8(!lighbx8);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose8 = () => {
    setlighbx8(!lighbx8);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };
  const [lighbx9, setlighbx9] = React.useState(false);
  const handleBtnClick9 = () => {
    setlighbx9(!lighbx9);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose9 = () => {
    setlighbx9(!lighbx9);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };
  const [lighbx10, setlighbx10] = React.useState(false);
  const handleBtnClick10 = () => {
    setlighbx10(!lighbx10);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose10 = () => {
    setlighbx10(!lighbx10);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };
  const [lighbx11, setlighbx11] = React.useState(false);
  const handleBtnClick11 = () => {
    setlighbx11(!lighbx11);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose11 = () => {
    setlighbx11(!lighbx11);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };
  const [lighbx12, setlighbx12] = React.useState(false);
  const handleBtnClick12 = () => {
    setlighbx12(!lighbx12);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose12 = () => {
    setlighbx12(!lighbx12);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };
  return <div className="container">
            <GlobalStyles />
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2 className='letter-spacing-p'>Portofoliu</h2>
                    <div className="space-border"></div>
                </div>
            </div>
            <Masonry className={"row my-gallery-class"} elementType={"div"}>
                <div className="col-lg-4 image-element-class de_modal de_modal" onClick={handleBtnClick} data-aos="fade-up" data-aos-once="true">
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Operativ CNC</h3>
                            <h5 className="d-tag">website</h5>
                        </div>
                        <img src="./img/gallery/1.jpg" alt="gallery" />
                    </div>
                </div>

                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick2} data-aos="fade-up" data-aos-once="true">
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Apex Lounge</h3>
                            <h5 className="d-tag">website</h5>
                        </div>
                        <img src="./img/gallery/2.png" alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick3} data-aos="fade-up" data-aos-once="true">
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Getyuu</h3>
                            <h5 className="d-tag">website</h5>
                        </div>
                        <img src="./img/gallery/3.jpg" alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick4} data-aos="fade-up" data-aos-once="true">
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Help a Pet</h3>
                            <h5 className="d-tag">website</h5>
                        </div>
                        <img src="./img/gallery/4.jpg" alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick5} data-aos="fade-up" data-aos-once="true">
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Drive suite</h3>
                            <h5 className="d-tag">website</h5>
                        </div>
                        <img src="./img/gallery/5.jpg" alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick6} data-aos="fade-up" data-aos-once="true">
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Alfa imobiliare</h3>
                            <h5 className="d-tag">website</h5>
                        </div>
                        <img src="./img/gallery/6.jpg" alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick7} data-aos="fade-up" data-aos-once="true">
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Label Engine</h3>
                            <h5 className="d-tag">website</h5>
                        </div>
                        <img src="./img/gallery/7.jpg" alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick8} data-aos="fade-up" data-aos-once="true">
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Help a Pet</h3>
                            <h5 className="d-tag">mobile app</h5>
                        </div>
                        <img src="./img/gallery/8.png" alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick9} data-aos="fade-up" data-aos-once="true">
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Frecventa 77</h3>
                            <h5 className="d-tag">mobile app</h5>
                        </div>
                        <img src="./img/gallery/9.png" alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick10} data-aos="fade-up" data-aos-once="true">
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Sandra & Petrut</h3>
                            <h5 className="d-tag">Web app</h5>
                        </div>
                        <img src="./img/gallery/10.png" alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick11} data-aos="fade-up" data-aos-once="true">
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Leo Tattoo</h3>
                            <h5 className="d-tag">Website</h5>
                        </div>
                        <img src="./img/gallery/11.png" alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick12} data-aos="fade-up" data-aos-once="true">
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Frecventa 77 Web</h3>
                            <h5 className="d-tag">Website</h5>
                        </div>
                        <img src="./img/gallery/12.png" alt="gallery" />
                    </div>
                </div>
            </Masonry>

            {/* lightbox */}
            {lighbx && <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/gallery/1.jpg" alt="" className="img-fluid" />
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Operativ CNC</h3>
                                    <p>Descoperiți lumea tehnologiei CNC prin intermediul site-ului Operative CNC! Aici veți găsi o prezentare captivantă a produselor CNC, care transformă procesele industriale prin prelucrarea de precizie. Indiferent dacă sunteți interesat de frezarea, strunjirea sau gravarea CNC, acest site vă oferă informații și detalii esențiale despre utilaje și aplicații. Explorați acum pentru a descoperi potențialul nelimitat al producției CNC.</p>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa fa-file-text-o"></i>Tip: <span>Website</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-calendar-o"></i>Year: <span>2022</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-external-link"></i>Preview: <span><a href="https://operativcnc.ro/" target="_blank" rel="noreferrer">operativcnc.ro</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            {lighbx2 && <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose2}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/gallery/2.png" alt="" className="img-fluid" />
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Apex Lounge</h3>
                                    <p>Îmbunătățiți-vă personajele și armele preferate în jocul Apex Legends, cu ajutorul platformei Apex Lounge! Aici puteți găsi cele mai recente sfaturi și trucuri pentru a vă perfecționa abilitățile de joc și a obține avantajul competitiv dorit. Cu o colecție de informații despre personaje, arme, tactici și strategii, Apex Lounge vă ajută să deveniți un jucător de top în lumea Apex Legends. Intră acum în Lounge și ia-ți jocul la un alt nivel!</p>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa fa-file-text-o"></i>Tip: <span>Website</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-external-link"></i>Preview: <span><a href="https://www.apexlounge.a2hosted.com/" target="_blank" rel="noreferrer">apexlounge.a2hosted.com</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            {lighbx3 && <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose3}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/gallery/3.jpg" alt="" className="img-fluid" />
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Getyuu</h3>
                                    <p>Everyday goods delivered in as little as 30 minutes.
                                        Order your favourite drinks, snacks and grocery essentials and we will bring them to Yuu.</p>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa fa-file-text-o"></i>Tip: <span>Website</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-calendar-o"></i>Year: <span>2022</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-external-link"></i>Preview: <span><a href="https://getyuu.co.uk/" target="_blank" rel="noreferrer">https://getyuu.co.uk/</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            {lighbx4 && <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose4}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/gallery/4.jpg" alt="" className="img-fluid" />
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Help a Pet</h3>
                                    <p>De ce Help a Pet?
                                        Scopul aplicației este de a crește interesul pentru adopția animalelor de companie și a ușura acest proces cât mai mult.</p>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa fa-file-text-o"></i>Tip: <span>Website</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-calendar-o"></i>Year: <span>2022</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-external-link"></i>Preview: <span><a href="https://helpapetcare.com" target="_blank" rel="noreferrer">  helpapetcare.com</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            {lighbx5 && <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose5}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/gallery/5.jpg" alt="" className="img-fluid" />
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Drive Suite</h3>
                                    <p>Start your learning journey
                                        Drive Suite provides detailed lesson clips and info which have more real life scenarios for you to vision and adapt accordiongly.</p>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa fa-file-text-o"></i>Tip: <span>Website</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-calendar-o"></i>Year: <span>2022</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-external-link"></i>Preview: <span><a href="https://drivesuite.co.uk/" target="_blank" rel="noreferrer"> drivesuite.co.uk</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            {lighbx6 && <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose6}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/gallery/6.jpg" alt="" className="img-fluid" />
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Alfa imobiliare</h3>
                                    <p>Căutați casa sau locuința ideală? Alegeți aLFA Imobiliare, partenerul dvs. de încredere în procesul de cumpărare a proprietăților! Cu o experiență vastă în domeniul imobiliar, aLFA Imobiliare vă oferă o gamă variată de case și locuințe care se potrivesc nevoilor și preferințelor dvs. Echipa noastră de experți vă va ghida în întregul proces, oferindu-vă consultanță personalizată și sprijin în luarea deciziilor. Descoperiți acum oportunitățile imobiliare de la aLFA Imobiliare și începeți căutarea către noul dvs. cămin.</p>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa fa-file-text-o"></i>Tip: <span>Website</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-calendar-o"></i>Year: <span>2022</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-external-link"></i>Preview: <span><a href="https://www.alfaimobiliare.ro/" target="_blank" rel="noreferrer">alfaimobiliare.ro</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            {lighbx7 && <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose7}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/gallery/7.jpg" alt="" className="img-fluid" />
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Label Engine</h3>
                                    <p>Save time & money with our
                                        Automated USPS labelsStop overpaying for USPS labels! LabelEngine™ offers the most affordable prepaid labels in the market.
                                        With prices starting at just $3,5 saving you a ton of money as well as improving your margins.</p>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa fa-file-text-o"></i>Tip: <span>Website</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-calendar-o"></i>Year: <span>2022</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-external-link"></i>Preview: <span><a href="https://labelengine.io/" target="_blank" rel="noreferrer">labelengine.io</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            {lighbx8 && <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose8}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/gallery/8.png" alt="" className="img-fluid" />
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Help a Pet</h3>
                                    <p>Ajutați animalele cu aplicația mobilă Help a Pet! Dezvoltată folosind tehnologii precum React-Native, Redux, Style-components și Expo, această aplicație vă permite să vă implicați în găsirea și furnizarea de ajutor pentru animalele nevoiașe. De la un design UI/UX atrăgător la funcționalități complete, Help a Pet vă conectează cu o comunitate pasionată de protecția animalelor. </p>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa fa-file-text-o"></i>Tip: <span>Aplicatie Mobilă</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-calendar-o"></i>Year: <span>2022</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-external-link"></i>Preview: <span><a href="https://play.google.com/store/apps/details?id=com.apphelpapet.Helpapet" target="_blank" rel="noreferrer"> Help a Pet</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            {lighbx9 && <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose9}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/gallery/9.png" alt="" className="img-fluid" />
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Frecventa 77</h3>
                                    <p>Descoperiți aplicația mobilă F77, disponibilă pe platforma Play Store și iOS! Cu o interfață dezvoltată folosind tehnologii precum React-Native, Expo și Style-components, și un backend alimentat de tehnologia PHP Laravel, F77 vă oferă o experiență unică de utilizare. Fie că este vorba despre explorarea conținutului captivant sau interacțiunea cu funcționalitățile sale, această aplicație este perfectă pentru a vă satisface nevoile. </p>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa fa-file-text-o"></i>Tip: <span>Aplicatie Mobilă</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-external-link"></i>Preview: <span><a href="https://play.google.com/store/apps/details?id=com.frecventa77" target="_blank" rel="noreferrer"> Frecventa 77</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            {lighbx10 && <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose10}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/gallery/10.png" alt="" className="img-fluid" />
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Sandra & Petrut (S.A.P.)</h3>
                                    <p>Next.js Web app pentru Sandra si Petruț, artisti locali din Bacau, pentru a sustine muzica lor si pentru dezovoltarea lor ca artisti.</p>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa fa-file-text-o"></i>Tip: <span>Aplicatie Mobilă</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-external-link"></i>Preview: <span><a href="https://sapstudio.co" target="_blank" rel="noreferrer"> S.A.P. Studio</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            {lighbx11 && <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose11}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/gallery/11.png" alt="" className="img-fluid" />
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Leo Tattoo</h3>
                                    <p>React.js website, pentru un Tattoo Artist local cu mult potential.</p>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa fa-file-text-o"></i>Tip: <span>Website</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-external-link"></i>Preview: <span><a href="https://leo-tattoo-website.vercel.app" target="_blank" rel="noreferrer"> Leo Tattoo</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            {lighbx12 && <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose12}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <img src="./img/gallery/12.png" alt="" className="img-fluid" />
                                </div>

                                <div className="col-lg-4 de_project-info">
                                    <h3>Frecventa 77 web</h3>
                                    <p>React.js website, pentru un Studio de Muzica din bacau cu peste 3 ani de activitate.</p>
                                    <div className="de_project-details">
                                        <div className="d-field">
                                            <i className="fa fa-file-text-o"></i>Tip: <span>Website</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-calendar-o"></i>Year: <span>2023</span>
                                        </div>
                                        <div className="d-field">
                                            <i className="fa fa-external-link"></i>Preview: <span><a href="https://frecventa77-website.vercel.app" target="_blank" rel="noreferrer"> F77 Website</a></span>
                                        </div>
                                    </div>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}




        </div>;
};
const GallerySection = Gallery;
export default GallerySection;