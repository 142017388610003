import React from 'react';
// import emailjs from 'emailjs-com';
import axios from 'axios';
import { Parallax } from "react-parallax";
import AOS from 'aos';
import { block } from "million/react";
import { CRYPTO_EXCELLENCE_TOKEN } from "../constants";
AOS.init();

// http://mail.techwizards.ro

const image1 = "./img/background/5.jpg";
const contact = function () {
  const sendEmail = async e => {
    e.preventDefault();
    const success = document.getElementById("success");
    const button = document.getElementById("send_message");
    const failed = document.getElementById("failed");
    const name_input = document.getElementById('name');
    const email_input = document.getElementById('email');
    const phone_input = document.getElementById('phone');
    const message_input = document.getElementById('message');
    const name = name_input.value;
    const email = email_input.value;
    const phone = phone_input.value;
    const message = message_input.value;
    if (email === '') {
      alert('Email is required');
      return;
    }
    const formData = {
      name: name,
      email: email,
      phone: phone,
      message: message
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Crypto-Excellence-Token': CRYPTO_EXCELLENCE_TOKEN
      }
    };
    try {
      await axios.post('https://cryptoexcellence.club/api/contact-request', formData, config);
      console.log('Message sent successfully');
      success.classList.add('show');
      button.classList.add('show');
      failed.classList.remove('show');
      setTimeout(() => {
        success.classList.remove('show');
      }, 3000);
      name_input.value = '';
      email_input.value = '';
      phone_input.value = '';
      message_input.value = '';
    } catch (err) {
      console.error(err.response.data.errors);
      failed.classList.add('show');
      let error_message;
      if (err.response.data.errors) {
        error_message = JSON.stringify(err.response.data.errors);
      } else if (err.response.data.message) {
        error_message = err.response.data.message;
      } else {
        error_message = 'Unknown error';
      }
      failed.innerHTML = error_message.replace(/[\[\]"]/g, "");
    }
  };
  return <div className="section bg-top bg-bottom py-0">
            <Parallax className="py-5" bgImage={image1} strength={300}>
                <div className="py-5 position-relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center" data-aos="fade-up" data-aos-once="true" data-aos-delay="0" data-aos-duration="1000" data-aos-easing="ease">
                                <h2 className='letter-spacing-p'>Contact</h2>
                                <div className="space-border"></div>
                                <p className='letter-spacing-p'>TechWizards este un suport real pentru clienti in a-si construi o prezenta online cat mai consistenta. Oferim servicii de creare identitate vizuala, promovare on-line sau off-line, creare de aplicatii desktop sau mobile si multe alte solutii integrate.
                                    Pentru detalii, scrie-ne si hai sa discutam mai multe!</p>
                                <div className="spacer-double"></div>
                            </div>
                        </div>
                        <div className="col-lg-8 offset-lg-2" data-aos="fade-up" data-aos-once="true" data-aos-delay="200" data-aos-duration="1000" data-aos-easing="ease">
                            <div className="contact_form_wrapper">
                                <form name="contactForm" id="contact_form" className="form-border" onSubmit={sendEmail}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="field-set">
                                                <input type="text" name="Name" id="name" max={120} className="form-control" placeholder="Nume" required />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="field-set">
                                                <input type="email" name="Email" id="email" max={120} className="form-control" placeholder="E-mail" required />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="field-set">
                                                <input type="text" name="phone" max={10} id="phone" className="form-control" placeholder="Telefon" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <div className="field-set">
                                            <textarea name="message" id="message" maxLength={10000} className="form-control" placeholder="Mesajul tău" required></textarea>
                                        </div>
                                        <div id='success' className='hide'>Mesajul tau a fost trimis...</div>
                                        <div id='failed' className='hide'>Mesaj netrimis...</div>
                                        <div id='submit' className="mt30">
                                            <button type='submit' id='send_message' className="btn-main letter-spacing-p">
                                                Trimite
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="spacer-double"></div>
                        </div>
                    </div>
                </div>
            </Parallax>
        </div>;
};
const contactSection = contact;
export default contactSection;