import React, { useState, useEffect } from 'react';
import desktopImage from '../assets/loader-logo.png';
import mobileImage from '../assets/loader-logo.png';
import { block } from "million/react";
const Loader = function ({
  children
}) {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);

    // Check if the screen is mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint if needed
    };

    handleResize(); // Initial check

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return <div id='mainpreloader'>
      {loading ? <div className='preloader fadeOut'>
          <div className="mainpreloader">
            <img src={isMobile ? mobileImage : desktopImage} alt="loading" style={{
          maxWidth: '100%',
          height: 'auto'
        }} />
          </div>
        </div> : children}
    </div>;
};
const loaderSection = Loader;
export default loaderSection;