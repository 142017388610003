import React from 'react';
import Typed from "react-typed";
import { block } from "million/react";
import AOS from 'aos';
AOS.init({
  offset: 200,
  duration: 800,
  easing: 'ease-in-out-sine',
  delay: 200,
  mirror: true
});
const hero = function () {
  return <div className="v-center">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h6 className="color" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000" data-aos-easing="ease" data-aos-once="true">Ce suntem
                        </h6>
                        <div className="spacer-20"></div>
                        <div className="h1_big" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000" data-aos-easing="ease" data-aos-once="true">
                            <Typed strings={['Developeri de aplicatii mobile', 'Developeri fullstack', 'Deployment si mentenanta']} typeSpeed={60} backSpeed={50} loop loopDelay={2000} />
                        </div>
                        <ul className="list_location" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000" data-aos-easing="ease" data-aos-once="true">
                            <li><span className='letter-spacing-p'>Romania</span>Bacau</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>;
};
const heroSection = hero;
export default heroSection;