import React from 'react';
import "react-circular-progressbar/dist/styles.css";
import { block } from "million/react";
import AOS from 'aos';
AOS.init();
const hero2 = () => {
  return <P$ />;
};
const P$ = /*million:transform*/block(({}) => {
  return <div className="v-center">
			<div className="container">
				<div className="row">
					<div className="col-md-12 text-center">
						<h2 className='letter-spacing-p' data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000" data-aos-easing="ease" data-aos-once="true">Despre noi</h2>
						<div className="space-border" data-aos="fade-up" data-aos-delay="20" data-aos-duration="1000" data-aos-easing="ease" data-aos-once="true"></div>
					</div>
					<div className="col-md-8 text-center m-auto" data-aos="fade-up" data-aos-delay="60" data-aos-duration="1000" data-aos-easing="ease" data-aos-once="true">
						<p className='letter-spacing-p'>

							Vrei sa faci o aplicatie web sau mobile originala in un buget decent ? <br />
							Noi credem ca te putem ajuta.

							Suntem o echipă de specialiști având fiecare o experiență individuală de 5 ani în dezvoltarea de aplicații web și mobile. Susținem că tehnologia ar trebui să servească oamenilor cu viziune, și de aceea, ne dedicăm total fiecărui proiect pentru cu cele mai eficiente si noi tehnologii.

							Pentru a ne asigura că procesul de dezvoltare este rapid, eficient și produce rezultate de excepție, aplicăm cele mai populare și dovedite tehnologii, garantând astfel un proces de dezvoltare lipsit de fricțiune. Ne bazăm pe tehnologii precum Laravel, React.js și React Native, cu care lucrăm în mod constant.

							Ne angajăm într-o comunicare deschisă și transparentă cu clienții noștri, asigurându-ne că fiecare proiect este dus la bun sfârșit, de la brief-ul inițial până la hostarea lor pe web sau publicarea acestora pe platformele de distribuție mobile.

							Fie că aveți nevoie de o aplicație mobilă sofisticată sau de un site web dinamic, suntem echipa potrivită pentru a vă ajuta să transformați viziunea în realitate. Suntem pregătiți să preluăm provocările cele mai dificile și să le transformăm în oportunități de succes.
						</p>
					</div>
				</div>
			</div>
		</div>;
}, {
  svg: false,
  shouldUpdate: (a, b) => true
});
hero2.__block_callable__ = true;
const heroApp = hero2;
export default heroApp;